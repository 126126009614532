

export default class TicketManager {
    slots: Array<any> = [];
    slotSize:number;
    queue:Array<any> = [];

    constructor(pSlotsCount:number){
        //this.slots = new Array(pSlotsCount);

        this.slotSize = pSlotsCount;
    }

    async getNewTicket(){
        let vReturn;
        const vTicket = {promise:new Promise((res)=>vReturn = res),state:'pending',id:window.crypto['randomUUID'](),waitPromise:null};
        
        this.queue.push(vTicket);

        if(this.slots.length <= this.slotSize){
            this.slots.push(vTicket);
            return vReturn;
        }

        let waitPromise:any;
        vTicket.waitPromise = new Promise((res)=>waitPromise = res);
        
        
        const vNextTicket = this.queue.find(x=>x.waitPromise == null && x.id !== vTicket.id);
        vNextTicket.waitPromise = new Promise((res)=>waitPromise = res);
        await vNextTicket.promise;
        waitPromise();

        /*
        
        const vNextTicket = this.slots.find(x=>x.waitPromise == null);


     
        if(vNextTicket){
            let waitPromise;
            vNextTicket.waitPromise = new Promise((res)=>waitPromise = res);
            await vNextTicket.promise;
            waitPromise();
*/

           /* const vIndex = this.slots.findIndex(x=>x.id == vNextTicket.id);
            this.slots.splice(vIndex,1);
            const queueTicket = this.queue.find(x=>x.state=="waiting");
            queueTicket.state = "inqueue";
            queueTicket.waitPromise();*/
     /*   }else{
            const vNextQTicket = this.queue.find(x=>x.waitPromise == null && x.id !== vTicket.id);
            
            await vNextQTicket.promise;

            await vNextQTicket.waitPromise;



            vTicket.state = "waiting";
            let waitPromise;
            vTicket.waitPromise = new Promise((res)=>waitPromise = res)
            this.queue.push(vTicket);
            await waitPromise;
        }*/
        
        return vReturn;
        //retunr new Promise()
    }

    async _getTicketFromQueue(){

    }

}
